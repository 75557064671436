<template>
  <div>

  </div>
</template>

<!-- envirothon 也是用这个文件  修改fbla 时候 要吧数据改回来 

  vue.config.js
  public 文件
  home文件 requestCode
  loginpage文件 title 
  ProjectList文件 小程序名称
  router title  fbla
  store.js 名称
-->


<script>
import {getProjectCode, setProjectCode, setUserId, setUserType} from "@/utils/store";

export default {
  name: 'Home',
  mounted() {
    if (false) {
      setUserId('6215c18ba4640ba1118b466d')
      setProjectCode('22-23FBLconference_Biz')
      setUserType('student')
      this.$router.replace({path: "/projects"})
      return
    }
    let project_code = getProjectCode()
    if (project_code && project_code != '') {
      this.$router.replace({path: "/projects"})
      return
    }
    this.requestCode()
  },
  methods: {
    requestCode() {
      const appId = 'wxe90941615719add0'
      const redirectUrl = 'http://fbl-fwh.learningfirst.cn/link'
      // const appId = 'wx7064975bf6cf8c16'
      // const redirectUrl = 'http://envirothon-fwh.learningfirst.cn/link'

      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect&connect_redirect=1'
    }
  }
}
</script>

<style scoped>

</style>
